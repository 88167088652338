import { Injectable } from '@angular/core';
import { ID, Models, Permission, Query, Role } from 'appwrite';
import { Api } from 'src/app/helpers/api';
import { LogbookItem } from 'src/app/shared/objects/logbook-item';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LogbookService {

  constructor() { }

  createLogbookItem(logbookItem: LogbookItem, teamId: string, writePermissions: string[]) {
    return Api.database.createDocument(environment.database, "TenderLogItems", ID.unique(), this.createPartialLogbookItem(logbookItem), [Permission.read(Role.team(teamId)), ...writePermissions]);
  }

  async getLogbookItemsOfTender(tenderId: string){
    var resultCount = 50;
    var resultList: LogbookItem[] = [];
    var lastId: string | undefined = undefined;
    var retrievedObjects: Models.DocumentList<LogbookItem>;

    while (resultCount == 50) {
      if(lastId){
        retrievedObjects = await Api.database.listDocuments<LogbookItem>(
          environment.database,
          "TenderLogItems",
          [Query.limit(50), Query.equal('tenderId', tenderId), Query.cursorAfter(lastId)]
        );
      } else {
        retrievedObjects = await Api.database.listDocuments<LogbookItem>(
          environment.database,
          "TenderLogItems",
          [Query.limit(50), Query.equal('tenderId', tenderId)]
        );
      }

      lastId = retrievedObjects?.documents[retrievedObjects.documents.length - 1]?.$id;
      resultList.push(...retrievedObjects.documents);
      resultCount = retrievedObjects.documents.length;
    }

    return resultList;
  }

  deleteLogbookItem(logbookItem: LogbookItem) {
    return Api.database.deleteDocument(environment.database, "TenderLogItems", logbookItem.$id);
  }

  private createPartialLogbookItem(logbookItem: LogbookItem) : Partial<LogbookItem>{
    return {
      message: logbookItem.message,
      createdBy: logbookItem.createdBy,
      createdDate: logbookItem.createdDate,
      tenderId: logbookItem.tenderId
    };
  }
}
